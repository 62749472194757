<template>
  <div>
    <!-- 위험등록부 목록 -->
    <c-table
      ref="table"
      title="LBL0001203"
      tableId="riskRegister"
      :columns="gridRisk.columns"
      :data="riskReduce.riskbooks" 
      :merge="gridRisk.merge"
      :gridHeight="setheight"
      :editable="editable&&!disabled"
      selection="multiple"
      rowKey="ramSignificantRiskRegisterId"
      checkDisableColumn="riskRegisterFlag"
    >
      <!-- 버튼 영역 -->
      <template slot="table-button">
        <q-btn-group outline >
          <c-btn v-if="editable&&!disabled" label="LBLREMOVE" icon="remove" @btnClicked="removeRisk" />
        </q-btn-group>
      </template>
      <template v-slot:customArea="{ props, col }">
        <component
          :is="imprComponent"
          :col="col"
          :props="props"
          :inputEditable="false"
          :requestContentsCols="requestContentsCols"
          tableKey="ramKpsrAssessScenarioId"
          ibmTaskTypeCd="ITT0000005"
          ibmTaskUnderTypeCd="ITTU000005"
          @imprChange="imprChange"
        />
      </template>
    </c-table>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'kpsr-risk-register',
  props: {
    param: {
      type: Object,
      default: () => ({
        ramRiskAssessmentPlanId: '',
        ramStepCd: '',
      }),
    },
    riskReduce: {
      type: Object,
      default: function() {
        return {
          imprs: [],
          riskbooks: [],
        }
      },
    },
    height: {
      type: String,
      default: function() {
        return '';
      },
    },
  },
  data() {
    return {
      gridRisk: {
        merge: [
          { index: 0, colName: 'processName' },
          { index: 1, colName: 'processDiagram' },
          { index: 2, colName: 'processDiagramNode' },
          { index: 3, colName: 'processDiagramNodeRisk' },
        ],
        columns: [
          {
            name: 'processName',
            field: 'processName',
            label: 'LBLPROCESS',
            align: 'center',
            style: 'width:100px',
            sortable: false,
          },
          {
            name: 'diagramTitle',
            field: 'diagramTitle',
            description: 'diagramNo',
            // 도면
            label: 'LBL0001001',
            align: 'center',
            style: 'width:100px',
            sortable: false,
          },
          {
            name: 'nodeTitle',
            field: 'nodeTitle',
            description: 'nodeNo',
            // 노드
            label: 'LBL0001004',
            align: 'center',
            style: 'width:120px',
            sortable: false,
          },
          {
            name: 'riskTypName',
            field: 'riskTypName',
            // 위험형태
            label: 'LBL0001026',
            align: 'center',
            style: 'width:100px',
            sortable: false,
          },
          {
            name: 'riskOccurrenceCause',
            field: 'riskOccurrenceCause',
            // 원인
            label: 'LBL0001006',
            align: 'left',
            style: 'width:200px',
            sortable: false,
          },
          {
            name: 'riskOccurrenceResult',
            field: 'riskOccurrenceResult',
            // 결과
            label: 'LBL0001007',
            align: 'left',
            style: 'width:200px',
            sortable: false,
          },
          {
            name: 'relationLaw',
            field: 'relationLaw',
            // 관련법규
            label: 'LBL0001021',
            align: 'left',
            style: 'width:120px',
            sortable: false,
          },
          {
            name: 'relationProblem',
            field: 'relationProblem',
            // 관련문제사항
            label: 'LBL0001024',
            align: 'left',
            style: 'width:120px',
            sortable: false,
          },
          {
            name: 'currentSafetyMeasures',
            field: 'currentSafetyMeasures',
            // 현재안전조치
            label: 'LBL0001025',
            align: 'left',
            style: 'width:170px',
            sortable: false,
          },
          {
            name: 'impr',
            field: 'impr',
            // 개선진행
            label: 'LBL0001178',
            align: 'center',
            child: [
              {
                name: 'customCol',
                field: 'customCol',
                label: 'LBLIMPRNO_PROGRESS',
                align: 'center',
                style: 'width:130px',
                type: 'custom',
                sortable: false
              },
              {
                name: 'improvementMeasures',
                field: 'improvementMeasures',
                // 개선대책
                label: 'LBL0001408',
                align: 'left',
                style: 'width:200px',
                sortable: false
              },
            ]
          },
        ],
        data: [],
        height: '500px'
      },
      imprComponent: () => import(`${'@/pages/common/ibm/tableImpr.vue'}`),
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        width: '80%',
        param: {},
        closeCallback: null,
      },
      requestImprRow: null,
      imprData: null,
      editable: true,
      deleteUrl: '',
    };
  },
  computed: {
    setheight() {
      let pxRemove = this.height.replace('px', '');
      return this.height && this.height.indexOf('px') > -1 && !isNaN(pxRemove) ? (Number(pxRemove) - 50) + 'px' : '500px'
    },
    disabled() {
      return this.param.ramStepCd === 'RRS0000015'
    },
    requestContentsCols() {
      return ['riskTypName', 'riskOccurrenceCause'];
    }
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.deleteUrl = this.$format(transactionConfig.ram.riskRegister.delete.url, 'ITT0000005')
      // code setting
      // list setting
    },
    imprChange() {
      this.$refs['table'].$refs['compo-table'].resetVirtualScroll();
      this.research();
    },
    research() {
      this.$emit('research');
    },
    linkClick(data, row) {
      this.requestImprRow = row
      this.imprData = data;
      this.popupOptions.title = 'LBLIMPROVE';
      this.popupOptions.param = {
        sopImprovementId: data.sopImprovementId,
      };
      this.popupOptions.target = () => import(`${'@/pages/sop/ibm/imprDetail.vue'}`);
      this.popupOptions.width = '80%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeImprPopup;
    },
    closeImprPopup(result, flag) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (result && result.col1) {
        if (flag !== 'delete') {
          if (result.col1.ibmStepCd === 'IS00000010' || result.col1.ibmStepCd === 'IS00000015' || result.col1.ibmStepCd === 'IS00000020') {
            this.requestImprRow.riskRegisterFlag = true
          }
          let splitId = this.$_.split(this.requestImprRow.sopImprovementIds, ',');
          let splitStep = this.$_.split(this.requestImprRow.ibmStepNames, ',');
          if (splitId && splitId.length > 0) {
            let idIndex = this.$_.indexOf(splitId, result.col1.sopImprovementId);
            splitStep[idIndex] = result.col1.ibmStepName;
            this.requestImprRow.ibmStepNames = splitStep.toString();
          }
        } else {
          let splitId = this.$_.split(this.requestImprRow.sopImprovementIds, ',');
          let splitStep = this.$_.split(this.requestImprRow.ibmStepNames, ',');
          let splitClass = this.$_.split(this.requestImprRow.ibmClassCds, ',');
          if (splitId && splitId.length > 0) {
            let idIndex = this.$_.indexOf(splitId, result.col1.sopImprovementId);
            splitId.splice(idIndex, 1)
            splitStep.splice(idIndex, 1)
            splitClass.splice(idIndex, 1)
            this.$set(this.requestImprRow, 'sopImprovementIds', splitId.toString())
            this.$set(this.requestImprRow, 'ibmStepNames', splitStep.toString())
            this.$set(this.requestImprRow, 'ibmClassCds', splitClass.toString())
          }
        }
        this.$refs['table'].$refs['compo-table'].resetVirtualScroll();
        this.research();
      }
    },
    /* eslint-disable no-unused-vars */
    innerBtnClicked(col, props, btn) {
      this.requestImprRow = props.row
      let requestContents = props.row.riskTypName;
      if (props.row.riskOccurrenceCause) {
        requestContents += ' / ' + props.row.riskOccurrenceCause
      }
      this.popupOptions.title = 'LBLIMPRREQUEST';
      this.popupOptions.param = {
        requestContents: requestContents,
        relationTableKey: props.row.ramKpsrAssessScenarioId,
        ibmTaskTypeCd: 'ITT0000005',
        ibmTaskUnderTypeCd: 'ITTU000005',
      };
      this.popupOptions.target = () => import(`${'@/pages/sop/ibm/imprRequest.vue'}`);
      this.popupOptions.width = '80%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeAddImprPopup;
    },
    closeAddImprPopup(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;

      if (data && data.length > 0) {
        this.$_.forEach(data, item => {
          let sData = this.requestImprRow.sopImprovementIds ? this.requestImprRow.sopImprovementIds : '';
          if (sData.indexOf(item.sopImprovementId) === -1) {
            this.requestImprRow.sopImprovementIds = this.requestImprRow.sopImprovementIds ? 
              this.requestImprRow.sopImprovementIds + ',' + item.sopImprovementId : item.sopImprovementId
            this.requestImprRow.ibmStepNames = this.requestImprRow.ibmStepNames ? 
              this.requestImprRow.ibmStepNames + ',' + item.ibmStepName : item.ibmStepName
            this.requestImprRow.ibmClassCds = this.requestImprRow.ibmClassCds ? 
              this.requestImprRow.ibmClassCds + ',' + item.ibmClassCd : item.ibmClassCd
            this.requestImprRow.ibmTooltip = this.requestImprRow.ibmTooltip ? 
              this.requestImprRow.ibmTooltip + '│' + item.ibmTooltip : item.ibmTooltip
          }
        })
        this.$refs['table'].$refs['compo-table'].resetVirtualScroll();
        this.research();
      }
    },
    removeRisk() {
      let selectData = this.$refs['table'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: 'LBLGUIDE', // 안내
          message: 'MSGNOITEMS', // 선택된 항목이 없습니다. 
          type: 'warning', // success / info / warning / error
        });
      } else {
        window.getApp.$emit('CONFIRM', {
          title: 'LBLCONFIRM',
          message: 'MSGREMOVE', // 삭제하시겠습니까?,
          // TODO : 필요시 추가하세요.
          type: 'info', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            let deleteData = [];
            this.$_.forEach(selectData, item => {
              deleteData.push({
                ramSignificantRiskRegisterId: item.ramSignificantRiskRegisterId,
                ramRiskAssessmentPlanId: item.ramRiskAssessmentPlanId,
                processCd: item.processCd,
                scenarioId: item.ramKpsrAssessScenarioId,
              })
            })
            this.$http.url = this.deleteUrl;
            this.$http.type = 'DELETE';
            this.$http.param = {
              data: Object.values(deleteData),
            };
            this.$http.request(() => {
              window.getApp.$emit('APP_REQUEST_SUCCESS');
              this.$_.forEach(selectData, item => {
                this.riskReduce.riskbooks.splice(this.$_.findIndex(this.riskReduce.riskbooks, { ramSignificantRiskRegisterId: item.ramSignificantRiskRegisterId }), 1)
              })
              this.$refs['table'].$refs['compo-table'].clearSelection();
              this.research();
            },);
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      }
    },
  }
};
</script>